import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const FormContainer = styled.div`
  margin: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Form = styled.form`
  width: 100%;
`;

const FormLabel = styled.label`
  font-weight: 700;
`;

const FormInput = styled.input`
  width: 100%;
  // border-radius: 0;
  font-size: 16px;
  padding: 12px 20px 5px 5px;
  margin: 8px 0;
  display: inline-block;
  outline: 0;
  background-color: transparent;
  background-image: linear-gradient(to right, #2d4ef5 0, #2d4ef5 70%);
  background-repeat: no-repeat;
  background-position: 0 calc(100% + 1px), 0 0;
  background-size: 100% 1px;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
  transition: all 0.3s ease;
  &:focus {
    border-color: transparent;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 20px;
  font-size: 16px;

  &:focus {
    outline: 0.5px solid blue;
  }
`;

const Wrapper = styled.div`
  margin: auto;
  padding: 2rem 4rem;
  max-width: 700px;
`;

export const Button = styled.button`
  -webkit-appearance: none;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  color: #757575;
  border: 1px solid #ddd;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 4rem 0 2rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  transition: 0.2s background-color ease;
  width: 30%;
  min-width: 100px;

  &:active,
  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
    outline: none;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  & + & {
    margin-left: 1rem;
  }
`;

const Contact = ({ data }) => {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <Wrapper>
        <Title as="h2" size="large">
          {data.contactJson.title}
        </Title>
        <FormContainer>
          <Form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <FormInput type="hidden" name="form-name" value="contact" />
            <p hidden>
              <FormLabel>
                Don`&apos;`t fill this out:{' '}
                <FormInput name="bot-field" onChange={handleChange} />
              </FormLabel>
            </p>
            <p>
              <FormLabel>
                Name
                <br />
                <FormInput type="text" name="name" onChange={handleChange} />
              </FormLabel>
            </p>
            <p>
              <FormLabel>
                Email
                <br />
                <FormInput type="email" name="email" onChange={handleChange} />
              </FormLabel>
            </p>
            <p>
              <FormLabel>
                Message
                <br />
                <FormTextArea name="message" onChange={handleChange} />
              </FormLabel>
            </p>
            <p style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit">Send</Button>
            </p>
          </Form>
        </FormContainer>
      </Wrapper>
    </Layout>
  );
};

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
